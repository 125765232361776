import React from "react";

import AdminLayout from "../../adminlayout/index";

import SEO from "components/seo/index";

import Recentplantationstatuspage from "components/admin/recentPlantation/recentplantationstatuspage";

const Recentplantationstatus = () => {
  return (
    <>
      <SEO title="Forests By Heartfulness admin" />
      <AdminLayout>
        <div>
          <Recentplantationstatuspage />
        </div>
      </AdminLayout>
    </>
  );
};

export default Recentplantationstatus;
