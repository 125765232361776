import React, { useState } from "react";
import "assets/styles/pages/sidebar.scss";
import "assets/styles/pages/dashboardstatus.scss";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import editiconpencil from "assets/images/admin/edit-icon.svg";
import binicon from "assets/images/admin/recycle-bin.png";
import "react-pro-sidebar/dist/css/styles.css";
import "assets/styles/pages/customheader.scss";

import AdminService from "services/admin/admin.service";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import ProjectViewthubnailslider from "../project-thumbnail-slider";
import Pagination from "react-js-pagination";

import noimage from "assets/images/noImage.png";
import { doAdminLogout } from "../login";
import { isMobile } from "react-device-detect";

export default function Recentplantationstatuspage() {
  const adminApi = new AdminService();
  const [plantationList, setPlantationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [trackId, setTrackId] = useState("");
  const [trackDelete, setTrackDelete] = useState(false);

  const deleteOpen = (data) => {
    setTrackId(data);
    setTrackDelete(true);
  };
  const trackClose = () => {
    setTrackDelete(false);
    setTrackId("");
  };

  const deleteplantation = async () => {
    setLoading(true);
    const inputs = {
      pageNumber: 0,
      pageSize: perPage,
      recentPlantationListFilter: {
        status: "ACTIVE",
      },
    };
    await adminApi
      .deleteRecentPlantation(inputs, trackId)
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          toast.success("Plantation Deleted Successfully");
          setTrackDelete(false);
          listPlanters();
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            toast.error("JWT Token has Expired");
            navigate("/admin/");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };
  const handlePageChange = async (pageNumber) => {
    await setActivePage(pageNumber);
    let inputs = {
      pageNumber: pageNumber - 1,
      pageSize: perPage,
    };
    setLoading(true);
    await adminApi
      .listRecentPlantation(inputs)
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          setPlantationList(data.data);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            toast.error("JWT Token has Expired");
            navigate("/admin");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };
  const listPlanters = async () => {
    const inputs = {
      pageNumber: 0,
      pageSize: perPage,
      // recentPlantationListFilter: {
      //   status: "ACTIVE",
      // },
    };
    setLoading(true);
    await adminApi
      .listRecentPlantation(inputs)
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          setActivePage(1);
          setPlantationList(data.data);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          validationMessage(error);
        }
      });
  };

  const validationMessage = (data) => {
    if (data.response.status === 401) {
      toast.error("Authentication failed");
      setTimeout(() => {
        doAdminLogout();
        navigate("/admin/");
      }, 3000);
    } else if (data.response.status === 403) {
      toast.error("Un Authorized user");
      setTimeout(() => {
        doAdminLogout();
        navigate("/admin/");
      }, 3000);
    } else {
      toast.error(data.response.data.message);
    }
  };
  const editPlantation = (data) => {
    const editData = data;
    let path1 = "/admin/recentplantationedit";
    let pathSet = `?id=${editData?.id}`;
    navigate(path1 + pathSet);
  };

  useEffect(() => {
    listPlanters();
  }, []);

  return (
    <>
      <div className="dashboard-status-wrapper">
        <div className="row">
          <div className="col-md-12">
            <h3 className="dark-theme-green margin-btm"> Recent Plantation</h3>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <Link
              to="/admin/addrecentplantation"
              className="btn btn-white-button fl-right"
            >
              + Add Recent Plantation
            </Link>
          </div>
        </div>

        <div className="row mg-top20">
          {plantationList.recentPlantation &&
            plantationList.recentPlantation.map((plantationItem, index) => {
              const imgArray = plantationItem?.imageResource?.map((item) => {
                return item.imageUrl;
              });
              return (
                <div className="col-md-6" key={index + 1}>
                  <div className="dashboard-card-wrapper">
                    <div className="wid-40 padding-10">
                      {plantationItem.imageResource &&
                      plantationItem.imageResource ? (
                        <ProjectViewthubnailslider
                          images={imgArray}
                          key={index + 1}
                        />
                      ) : (
                        <img src={noimage} alt="default Image" />
                      )}
                    </div>
                    <div className="dashboard-card-body wid-60 padding10">
                      <div className="dis-title-status">
                        <h5 className="heading-text">{plantationItem.title}</h5>
                        <span className="status-text right-hightlight-msg">
                          {plantationItem.status === "INACTIVE" ? (
                            <span style={{ color: "red" }}>Inactive</span>
                          ) : plantationItem.status === "ACTIVE" ? (
                            "Active"
                          ) : plantationItem.status === "COMPLETED" ? (
                            "Completed"
                          ) : null}
                        </span>
                      </div>
                      <span
                        className="date-time-text"
                        dangerouslySetInnerHTML={{
                          __html: `${plantationItem.description.slice(
                            0,
                            150
                          )}...`,
                        }}
                      />

                      <div
                        className={
                          isMobile
                            ? `item-direction-wr1`
                            : `item-direction-wr right-alig-icon`
                        }
                      >
                        <span className="gap-circle-icon">
                          {" "}
                          <button
                            className="circle-view"
                            to="/admin/projectedit"
                            onClick={() => {
                              editPlantation(plantationItem);
                            }}
                          >
                            {" "}
                            <img
                              className="img-fluid"
                              src={editiconpencil}
                              alt="bellicon"
                            />
                          </button>{" "}
                        </span>
                        <span className="gap-circle-icon">
                          {" "}
                          <button
                            className="circle-view"
                            to="/admin/dashboardview"
                          >
                            {" "}
                            <img
                              className="img-fluid bin-w-pad"
                              onClick={() => {
                                deleteOpen(plantationItem.id);
                              }}
                              src={binicon}
                              alt="bellicon"
                            />
                          </button>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {loading === false && plantationList.totalNoOfRecords > 10 ? (
          <div>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={plantationList.totalNoOfRecords}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>

      <Modal show={trackDelete} centered>
        <Modal.Header closeButton onClick={trackClose}>
          <Modal.Title>Delete Plantation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={trackClose}
          >
            No
          </Button>
          <Button variant="primary" onClick={deleteplantation}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
